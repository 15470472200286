.component {
    // max-width: var(--wpe--content--max-width);
    // line-height: 1.6875;

    // Scope WordPress block styles within ContentWrapper.
    // @import 'src/styles/wordpress';
    

    // * {
    //     max-width: 100%;
    //     font-size: 16px;
    // }

    // figure {
    //     margin-left: 0;
    //     margin-right: 0;
    // }

    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6 {
    //     margin: 4.8rem 0;
    // }

    // strong {
    //     font-weight: 700;
    // }

    // a {
    //     color: var(--wpe--link--color);
    //     text-decoration: underline;

    //     &:hover,
    //     &:focus {
    //         color: var(--wpe--link--color);
    //         text-decoration: none;
    //     }
    // }

    // li {
    //     font-size: 1.6rem;
    // }

    img {
        display: block;
        height: auto;
        // max-width: 100%;
        width: 100%;
    }

    // .alignleft {
    //     display: inline;
    //     float: left;
    //     margin-right: 1.5em;
    // }

    // .alignright {
    //     display: inline;
    //     float: right;
    //     margin-left: 1.5em;
    // }

    // .aligncenter {
    //     clear: both;
    //     display: block;
    //     margin-left: auto;
    //     margin-right: auto;
    // }

    // code,
    // pre {
    //     color: var(--color-white);
    //     background: var(--color-black);
    // }

    // code {
    //     padding: 0.25rem 0.5rem;
    // }

    // pre {
    //     max-width: 100%;
    //     overflow: auto;
    //     padding: 1rem;
    // }

    // blockquote {
    //     border-top: 1px solid var(--color-black);
    //     border-bottom: 1px solid var(--color-black);
    //     font-style: italic;
    //     margin-top: 0;
    //     margin-left: 0;
    //     margin-right: 0;
    //     padding: 4rem 1rem 4rem;
    //     text-align: center;

    //     &::before {
    //         content: '”';
    //         display: block;
    //         font-size: 6rem;
    //         line-height: 0;
    //         margin: 2rem 0;
    //     }

    //     >*:last-child {
    //         margin-bottom: 0;
    //     }
    // }

    // table {
    //     border-collapse: collapse;
    //     width: 100%;
    // }

    // thead th {
    //     border-bottom: 1px solid var(--wpe--color--border);
    //     padding-bottom: 0.5em;
    // }

    // th {
    //     padding: 0.4rem 0;
    //     text-align: left;
    // }

    // tr {
    //     border-bottom: 1px solid var(--wpe--color--border);
    // }

    // td {
    //     padding: 0.4em;
    // }
}